@import url('../colors/colors.css');

.transcribed{
    /* UI Properties */
    background: var(--primary70) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.todo{
    /* UI Properties */
    background: var(--primary34) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.inprogress{
    /* UI Properties */
    background: var(--primary36) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.received{
    /* UI Properties */
    background: var(--primary28) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.notreceived{
    /* UI Properties */
    background: var(--primary30) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.claimsubmitted{
    /* UI Properties */
    background: var(--primary32) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.review{
    /* UI Properties */
    background: var(--primary64) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.finalReview{
    /* UI Properties */
    background: var(--primary64) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.primarytranscribed{
    /* UI Properties */
    background: var(--primary70) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    width: 130px !important;
    text-align: center !important;
    /* width: 130px !important; */
    white-space: nowrap !important;
    /* margin-left: 8px; */
}
.primarytodo{
    /* UI Properties */
    background: var(--primary34) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    text-align: center !important;
    width: 130px !important;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    /* width: 130px !important; */
    /* margin-left: 8px; */
    padding: 0px 5px 0px 5px !important;
}
.primaryinprogress{
    /* UI Properties */
    background: var(--primary36) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    /* width: 130px !important; */
    text-align: center !important;
    width: 130px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    /* margin-left: 8px; */
    padding: 0px 5px 0px 5px !important;
}
.primaryreceived{
    /* UI Properties */
    background: var(--primary28) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    /* width: 130px !important; */
    text-align: center !important;
    width: 130px !important;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    /* margin-left: 8px; */
    padding: 0px 5px 0px 5px !important;
}
.primarynotreceived{
    /* UI Properties */
    background: var(--primary30) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    /* width: 130px !important; */
    width: 130px !important;
    text-align: center !important;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    /* margin-left: 8px; */
    white-space: nowrap;
    padding: 0px 5px 0px 5px !important;
}
.primaryclaimsubmitted{
    /* UI Properties */
    background: var(--primary32) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    /* width: 130px !important; */
    text-align: center !important;
    display: flex;
    width: 130px !important;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    /* margin-left: 8px; */
    white-space: nowrap;
    padding: 0px 5px 0px 5px !important;
}
.primaryreview{
     /* UI Properties */
     background: var(--primary64) 0% 0% no-repeat padding-box;
     border-radius: 4px;
     opacity: 1;
     display: flex;
     /* width: 130px !important; */
     width: 130px !important;
     text-align: center !important;
     justify-content: center;
     align-items: center;
     min-height: 24px;
     padding: 0px 5px 0px 5px !important;
}
.primaryFinalreview{
    /* UI Properties */
    background: var(--primary64) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    width: 130px !important;
    /* width: 130px !important; */
    text-align: center !important;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    padding: 0px 5px 0px 5px !important;
}